import request from '@/utils/request'


// 查询弹出广告列表
export function listAdvertisement(query) {
  return request({
    url: '/cigarette/advertisement/list',
    method: 'get',
    params: query
  })
}

// 查询弹出广告分页
export function pageAdvertisement(query) {
  return request({
    url: '/cigarette/advertisement/page',
    method: 'get',
    params: query
  })
}

// 查询弹出广告详细
export function getAdvertisement(data) {
  return request({
    url: '/cigarette/advertisement/detail',
    method: 'get',
    params: data
  })
}

// 新增弹出广告
export function addAdvertisement(data) {
  return request({
    url: '/cigarette/advertisement/add',
    method: 'post',
    data: data
  })
}

// 修改弹出广告
export function updateAdvertisement(data) {
  return request({
    url: '/cigarette/advertisement/edit',
    method: 'post',
    data: data
  })
}

// 删除弹出广告
export function delAdvertisement(data) {
  return request({
    url: '/cigarette/advertisement/delete',
    method: 'post',
    data: data
  })
}
