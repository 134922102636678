<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入备注" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Pop-up ad Name:" prop="name" >
        <a-input v-model="form.name" placeholder="" :maxLength="100"  />
        <div style="display: flex;justify-content: flex-end">{{ ((form.name && form.name.length) || 0) }}/100</div>
      </a-form-model-item>
      <a-form-model-item label="Effective Time:" prop="startTime" >
        <a-range-picker @change="onChange" v-model="effectiveTime" :locale="locale" :placeholder="['Select Date','Select Date']" />
      </a-form-model-item>
<!--      <a-form-model-item label="广告开始时间戳" prop="startTimeStamp" >-->
<!--        <a-date-picker style="width: 100%" v-model="form.startTimeStamp" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="广告结束时间" prop="endTime" >-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="广告结束时间戳" prop="endTimeStamp" >-->
<!--        <a-date-picker style="width: 100%" v-model="form.endTimeStamp" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Learn More URL:" prop="url" >
        <a-input v-model="form.url" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="Uploading ad Picture:" prop="picture" >
        <a-input v-model="form.picture" placeholder="" />
        <div style="display: flex;justify-content: flex-end">Recommended size of picture: 512 pixels * 512 pixels, supporting JPG and PNG formats.</div>
      </a-form-model-item>
      <a-form-model-item label="Status" prop="status" >
<!--        <a-select v-model="form.status">-->
<!--          <a-select-option :key="1">Enable</a-select-option>-->
<!--          <a-select-option :key="0">Disable</a-select-option>-->
<!--        </a-select>-->
        <a-radio-group v-model="form.status">
          <a-radio :value="1">
            Enable
          </a-radio>
          <a-radio :value="0">
            Disable
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            Save
          </a-button>
          <a-button type="dashed" @click="cancel">
            Cancel
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAdvertisement, addAdvertisement, updateAdvertisement } from '@/api/cigarette/advertisement'
import localeEn from 'ant-design-vue/es/date-picker/locale/en_US'
import moment from 'moment';
// import localeZh from 'ant-design-vue/es/date-picker/locale/zh_CN';
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      locale: localeEn,
      submitLoading: false,
      formTitle: '',
      effectiveTime: [],
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        name: null,

        startTime: null,

        startTimeStamp: null,

        endTime: null,

        endTimeStamp: null,

        url: null,

        picture: null,

        status: 1

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onChange(e,date) {
      console.log(e)
      this.form.startTime = date[0];
      this.form.endTime = date[1];
      console.log(date)
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        name: null,
        startTime: null,
        startTimeStamp: null,
        endTime: null,
        endTimeStamp: null,
        url: null,
        picture: null,
        status: 1
      }
      this.effectiveTime = null;
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = 'Add'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAdvertisement({"id":id}).then(response => {
        this.form = response.data;
        this.effectiveTime = [moment(this.form.startTime,'YYYY-MM-DD'),moment(this.form.endTime,'YYYY-MM-DD')]
        this.open = true
        this.formTitle = 'Modify'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAdvertisement(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAdvertisement(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
